import React, { useState, useEffect } from 'react';
import API from 'src/utils/apiCalling';
import { config } from 'src/utils/apiUrl';
import swal from 'sweetalert';
import Alert from 'src/alert/alert';
import AadharCardVerify from '../AadharCardVerify';
import { getLocalDataAsObject } from 'src/utils/CoustomStorage';
import { toast, ToastContainer } from 'react-toastify';
import {isPermission} from '../../../utils/common';
import moment from 'moment';
import { validateEmail, validateMobile2, validateName, validatePanNumber } from 'src/utils/ValidationHelper';
const PersonalDetails = props => {
  const api = new API();
  const [empName, setEmpName] = useState(false)
  const [empValidNumber, setEmpValidNumber] = useState(false)
  const [empPersonalEmail, setEmpPersonalEmail] = useState(false)
  const [empPanNumber, setEmpPanNumber] = useState(false)
  const [empEmergencyNumber,setEmpEmergencyNumber]=useState(false)
  const [empPresentCity ,setEmpPresentCity]=useState(false)
  const [empPresentstate,setEmpPresentState]=useState(false)
  const [empPermanentstate,setEmpPermanentstate]=useState(false)
  const [empPermanentCity,setEmpPermanentCity]=useState(false)
  let user = getLocalDataAsObject('user');
  let skip_permission_emp_id = user[0].emp_id;
  let skip_permission_emp_user_id = user[0].id;

  let { getEmployeeDetailStatus, empStatus, empDetails, onClickActive } = props;
  const [isDisabled, setIsDisabled] = useState(false);

  const [aadharVerified, setAadharVerified] = useState(false);
  const [isSkip, setIsSkip] = useState(false);
  const [documentStatus, setDocumentStatus] = useState();
  const [
    isAadharVerifiedSuccessfully,
    setIsAadharVerifiedSuccessfully,
  ] = useState(false);
  const [empData, setEmpData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [personalDetails, setPersonalDetails] = useState({
    emp_name: '',
    emp_dob: '',
    emp_gender: '',
    emp_marital_status: '',
    emp_present_address_street: '',
    emp_present_address_city: '',
    emp_present_address_state: '',
    emp_present_address_postal_code: '',
    emp_permanent_address_street: '',
    emp_permanent_address_city: '',
    emp_permanent_address_state: '',
    emp_permanent_address_postal_code: '',
    emp_mobile: '',
    emp_personal_email: '',
    emp_alternate_email: '',
    emp_uan_no: '',
    emp_esic_no: '',
    emp_aadhar_card: '',
    emp_pan_card: '',
    emp_emergency_mobile: '',
    emp_blood_group: '',
  });
  // const [empValidNumber, setEmpValidNumber] = useState(false)
  // const [empPersonalEmail, setEmpPersonalEmail] = useState(false)
  // const [empPanNumber, setEmpPanNumber] = useState(false)
  // const [empEmergencyNumber,setEmpEmergencyNumber]=useState(false)
  // const [empPresentCity ,setEmpPresentCity]=useState(false)
  // const [empPresentstate,setEmpPresentState]=useState(false)
  // const [updatedPersonalDetails,setUpdatdPersonalDetails] = useState({
  //   emp_name: "",
  //   emp_dob: "",
  //   emp_gender: "",
  //   emp_marital_status: "",
  //   emp_present_address_street: "",
  //   emp_present_address_city: "",
  //   emp_present_address_state: "",
  //   emp_present_address_postal_code: "",
  //   emp_permanent_address_street: "",
  //   emp_permanent_address_city: "",
  //   emp_permanent_address_state: "",
  //   emp_permanent_address_postal_code: "",
  //   emp_mobile: "",
  //   emp_personal_email: "",
  //   emp_alternate_email: "",
  //   emp_uan_no: "",
  //   emp_esic_no: "",
  //   emp_aadhar_card: "",
  //   emp_pan_card: "",
  //   emp_emergency_mobile: "",
  //   emp_blood_group: ""
  // });

  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isSubmit, setIsSubmit] = useState(true);
 
  // const [error, setError] = useState({ status: false, message: '' });

  const isAlertToggle = msg => {
    setIsAlert(!isAlert);
    setAlertMessage(msg);
  };
  const isAlertToggleClose = () => {
    setIsAlert(false);
  };

  const getDocumentStatus = async () => {
    let data = {
      user_id: empDetails && empDetails.id,
    };
    if (!data.user_id) {
      delete data.user_id;
    }
    let result = await api.get(config.getDocuumentStatus, true, data);
    if (result && result.code === 200) {
      if (result && result.data.length > 0) {
        if (result.data[0].aadhar_verification_status == '1') {
          setIsAadharVerifiedSuccessfully(true);
          if (empStatus && empStatus.personal_details_status == '0') {
            getAddharDetails();
          }
          setIsSkip(true);
        }
        setDocumentStatus(result.data[0]);
      }
    }
  };

  useEffect(() => {
    showSkipIfMatch();
    getPersonalDetails();
    getDocumentStatus();
  }, [empDetails]);

  // Form Controll On change Input Event functin
  const onChangeInputDetails = e => {
    const { name, value } = e.target;
    const regexName = /^[a-zA-Z ]*$/;
    const regexPAN = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const regexAadhar = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
    const regexMobile = /^[6-9][0-9]{9}$/;
    const regexEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const regexPinCode = /^[1-9]{1}[0-9]{2}[0-9]{3}$/;
    let employeeName = document.getElementById('name');
    let employeeMobile = document.getElementById('mobile');
    let employeeEmergencyMo = document.getElementById('emergency');
    let employeePAN = document.getElementById('pan');
    let employeePostalCodePresent = document.getElementById('pin_code1');
    let employeePostalCodePermanent = document.getElementById('pin_code');
    employeePAN.setAttribute('maxLength', '10');
    employeeMobile.setAttribute('maxLength', '10');
    employeeEmergencyMo.setAttribute('maxLength', '10');
    employeePostalCodePresent.setAttribute('maxLength', '6');
    employeePostalCodePermanent.setAttribute('maxLength', '6');
    let employeeAadhar = document.getElementById('aadhar');
    // let employeeEmail = document.getElementById('email');
    employeeAadhar.setAttribute('maxLength', '12');
    // employeeName.addEventListener('input', e => {
    //   const error = document.getElementById('error');
    //   const value = e.target.value;
    //   if (regexName.test(value)) {
    //     error.textContent = '';
    //   } else {
    //     error.textContent = 'Numeric and Special Characters are not allowed';

    //     employeeName.value = value.slice(0, value.length - 1);
    //   }
    // });
    // employeePAN.addEventListener('input', e => {
    //   const error = document.getElementById('error1');
    //   const value = e.target.value;
    //   if (value.length === 10) {
    //     if (regexPAN.test(value)) {
    //       error.textContent = '';
    //     } else {
    //       error.textContent = 'Enter a valid PAN No. like APPFD1224D';

    //       employeeName.value = value.slice(0, value.length - 1);
    //     }
    //   }
    // });
    // employeeAadhar.addEventListener('input', e => {
    //   const error = document.getElementById('error2');
    //   const value = e.target.value;
    //   if (value.length === 12) {
    //     if (regexAadhar.test(value)) {
    //       error.textContent = '';
    //     } else {
    //       error.textContent = 'Enter a valid 12 digit Aadhar No.';

    //       employeeName.value = value.slice(0, value.length - 1);
    //     }
    //   }
    // });
    // employeeMobile.addEventListener('input', e => {
    //   const error = document.getElementById('error4');
    //   const value = e.target.value;
    //   if (value.length === 10) {
    //     if (regexMobile.test(value)) {
    //       error.textContent = '';
    //     } else {
    //       error.textContent = 'Enter a valid 10 digit Mobile No.';
    //     }
    //   }
    // });
    // employeeEmergencyMo.addEventListener('input', e => {
    //   const error = document.getElementById('error3');
    //   const value = e.target.value;
    //   if (value.length === 10) {
    //     if (regexMobile.test(value)) {
    //       error.textContent = '';
    //     } else {
    //       error.textContent = 'Enter a valid 10 digit Mobile No.';

    //       employeeName.value = value.slice(0, value.length - 1);
    //     }
    //   }
    // });
    // employeeEmail.addEventListener('input', e => {
    //   const error = document.getElementById('error5');
    //   const value = e.target.value;
    //   if (regexEmail.test(value)) {
    //     error.textContent = '';
    //   } else {
    //     error.textContent = 'Enter a valid email';

    //     employeeName.value = value.slice(0, value.length - 1);
    //   }
    // });
    // employeePostalCodePresent.addEventListener('input', e => {
    //   const error = document.getElementById('error6');
    //   const value = e.target.value;
    //   if (value.length === 6) {
    //     if (regexPinCode.test(value)) {
    //       error.textContent = '';
    //     } else {
    //       error.textContent = 'Enter a valid 6 digit postal code';

    //       employeeName.value = value.slice(0, value.length - 1);
    //     }
    //   }
    // });
    // employeePostalCodePermanent.addEventListener('input', e => {
    //   const error = document.getElementById('error7');
    //   const value = e.target.value;
    //   if (value.length === 6) {
    //     if (regexPinCode.test(value)) {
    //       error.textContent = '';
    //     } else {
    //       error.textContent = 'Enter a valid 6 digit postal code';

    //       employeeName.value = value.slice(0, value.length - 1);
    //     }
    //   }
    // });
    checkValidation(name, value)
    setPersonalDetails({
      ...personalDetails,
      [name]: value,
    });
  };
  const checkValidation = (name, value) => {


    switch (name) {
      case "emp_mobile":
        let validationResult2 = validateMobile2(value)
        setEmpValidNumber(!validationResult2)
        break;
      case "emp_personal_email":
        let { msg, isValid } = validateEmail(value)
        // setEmpFirstRefMsg(msg)
        setEmpPersonalEmail(!isValid)
        break;
        case "emp_pan_card":
          let validationResult3 = validatePanNumber(value)
          setEmpPanNumber(!validationResult3)
          break;
        case "emp_emergency_mobile":
        let validationResult4 = validateMobile2(value)
        setEmpEmergencyNumber(!validationResult4)
        break;
        case "emp_present_address_city":
        let validationResult5 = validateName(value)
        setEmpPresentCity(!validationResult5)
        break;
        case "emp_name":
        let validationResult7 = validateName(value)
        setEmpName(!validationResult7)
        break;
        case "emp_present_address_state":
        let validationResult6 = validateName(value)
        setEmpPresentState(!validationResult6)
        break;
        case "emp_permanent_address_state":
        let validationResult8 = validateName(value)
        setEmpPermanentstate(!validationResult8)
        break;
        case "emp_permanent_address_city":
        let validationResult9 = validateName(value)
        setEmpPermanentCity(!validationResult9)
        break;
      default:
      // code block
    }
  }

  const getPersonalDetails = async () => {
    let data = {
      user_id: empDetails && empDetails.id,
    };
    if (!data.user_id) {
      delete data.user_id;
    }
    let result = await api.get(config.getEmployeePersonalDetails, true, data);
    if (result && result.code === 200) {
      if (result.data.length > 0) {
        setIsDisabled(true);
        setIsSubmit(false);
        setPersonalDetails(result.data[0]);
      }
    } else {
      // alert(result && result.message);
    }
  };

  // function for save personl details
  const savedetails = async event => {
    // if (error.status) {
    //   toast.error(error?.message);
    //   return;
    // }
    // let personalDetailKeyArray=Object.keys(personalDetails)
    // for(let i=0;i<=personalDetailKeyArray.length;i++){
    //   if(personalDetails[personalDetailKeyArray[i]]===""){
    //     toast.error(`please fill ${personalDetailKeyArray[i]}`)
    //     return
    //   }
    // }
    let data = personalDetails;
    data.user_id = empDetails && empDetails.id;
    if (!data.user_id) {
      delete data.user_id;
    }
    swal({
      content: true,
      title: 'Personal Details Will Be Saved !',
      text: `Are you sure to add Personal Details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.savedetails, data);
        if (result && result.code === 200) {
          toast.success(result.message);
          onClickActive(3);
          getEmployeeDetailStatus();
          setIsSubmit(false);
          getPersonalDetails();
        } else {
          toast.error(result.message);
        }
      }
    });
  };

  // function for save personl details
  const updatePersonalDetails = async event => {
    let data = personalDetails;
    swal({
      content: true,
      title: 'Personal Details Will Be Saved !',
      text: `Are you sure to update Personal Details`,
      icon: 'success',
      buttons: ['No, cancel it!', 'Update'],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.updatepersonaldetails, data);
        if (result && result.code === 200) {
          toast.success(result.message);
          getEmployeeDetailStatus();
          setIsSubmit(false);
          getPersonalDetails();
        } else {
          toast.error(result.message);
        }
      }
    });
  };

  // function for update  personal details
  const saveUpdatedPersonalDetails = async evert => {
    let data = personalDetails;
    data.user_id = empDetails && empDetails.id;
    if (!data.user_id) {
      delete data.user_id;
    }
    swal({
      content: true,
      title: 'Personal Details Will Be Saved !',
      text: `Are you sure want  Updated the Details`,
      icon: 'success',
      buttons: ['No,Cancel it!', 'Submit'],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        let result = await api.post(config.updatepersonaldetails, data);
        if (result && result.code === 200) {
          isAlertToggle(result && result.message);
          getEmployeeDetailStatus();
          getPersonalDetails();
        } else {
          isAlertToggle(result && result.message);
        }
      }
    });
  };

  const processedVerifiedFun = (val, details) => {
    aadharVarifiedSave(details);
    const address1 = details.address.landmark.concat(details.address.loc);
    const address = `${details.address.house} ,
      ${details.address.landmark} ,
      ${details.address.street} ,
      ${details.address.loc} ,
      PO - ${details.address.po}`;
    setAadharVerified(val);
    setPersonalDetails({
      ...personalDetails,
      emp_name: details.full_name,
      emp_dob: details.dob,
      emp_gender: details.gender,
      emp_permanent_address_street: address,
      emp_permanent_address_city: details.address.dist,
      emp_permanent_address_state: details.address.state,
      emp_permanent_address_postal_code: details.zip,
      emp_aadhar_card: details.aadhaar_number,
    });
  };

  const aadharVarifiedSave = async details => {
    let data = {
      details: JSON.stringify(details),
      user_id: empDetails && empDetails.id,
    };
    // return
    if (!data.user_id) {
      delete data.user_id;
    }
    let result = await api.post(config.verfiyAadhar, data);
    if (result && result.code === 200) {
      toast.success(result && result.message )
      // alert(result.message);
      setAadharVerified(true);
    } else {
      toast.error(result && result.message)
      // alert(result && result.message);
    }
  };

  const showSkipIfMatch = async () => {
    let arr = [];
    let result = await api.get(config.skip_aadhar, true);
    if (result && result.code === 200) {
      setEmpData(result && result.data);
      result &&
        result.data &&
        result.data.length > 0 &&
        result.data.map(item => {
          arr.push(item.emp_user_id);
        });
      setFilteredData(arr);
    } else {
      toast.error(result && result.message)
      // alert(result && result.message);
    }
  };

  const getAddharDetails = async () => {
    let data = {
      emp_user_id: empDetails && empDetails.id,
    };
    if (!data.emp_user_id) {
      delete data.emp_user_id;
    }
    let result = await api.get(config.getVerifiedAadharCardDetails, true, data);
    if (
      result &&
      result.code === 200 &&
      result.data &&
      result.data.length > 0
    ) {
      setAadharVerified(true);
      setPersonalDetails({
        ...personalDetails,
        emp_name: result.data[0].full_name,
        emp_dob: result.data[0].dob,
        emp_gender: result.data[0].gender,
        emp_permanent_address_street:
          result.data[0].house +
          ' , ' +
          result.data[0].landmark +
          ' , ' +
          result.data[0].street +
          ' , ' +
          result.data[0].loc +
          ' , ' +
          'PO - ' +
          result.data[0].po,
        emp_permanent_address_city: result.data[0].dist,
        emp_permanent_address_state: result.data[0].state,
        emp_permanent_address_postal_code: result.data[0].zip,
        emp_aadhar_card: result.data[0].aadhaar_number,
      });
    } else {
    }
  };

  return (
    <div>
      {isPermission('aadhaar_verify') && aadharVerified === false &&
        !isAadharVerifiedSuccessfully &&
        isSkip === false ? (
        <div>
          <AadharCardVerify processedVerifiedFun={processedVerifiedFun} />
        </div>
      ) : (
        <form>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label for="name">
                  Name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="name"
                  disabled={isPermission('aadhaar_verify') && aadharVerified ? true : false}
                  className="form-control"
                  id="name"
                  name="emp_name"
                  value={personalDetails.emp_name}
                  onChange={onChangeInputDetails}
                  placeholder="Enter your Name"
                />
                {/* <small style={{ color: 'red' }} id="error"></small> */}
                {
                empName && personalDetails.emp_name !=='' &&
                <span className='text-danger'>Name field should be allowed alphabetic characters.</span>
              }
              </div>

              <label for="exampleFormControlSelect1">
                Gender <span style={{ color: 'red' }}>*</span>
              </label>
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                value={personalDetails.emp_gender}
                name="emp_gender"
                disabled={isPermission('aadhaar_verify') && aadharVerified ? true : false}
                onChange={onChangeInputDetails}
              >
                {personalDetails.emp_gender ? (
                  <option value={personalDetails.emp_gender}>
                    {personalDetails.emp_gender}
                  </option>
                ) : (
                  <option>Select Gender</option>
                )}
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="Other">Other</option>
              </select>

              <div className="form-group" style={{ paddingTop: '15px' }}>
                <label for="exampleInputEmail1">
                  Personal Mobile No. <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="tel"
                  className="form-control"
                  value={personalDetails.emp_mobile}
                  id="mobile"
                  onChange={onChangeInputDetails}
                  name="emp_mobile"
                  placeholder="Enter Personal Mobile No."
                />
                {/* <small style={{ color: 'red' }} id="error4"></small> */}
                {
                  empValidNumber && personalDetails.emp_mobile!=='' &&
                  <span className='text-danger'>Please enter a valid mobile number, it must be of 10 digits and begins with 6, 7, 8 or 9.</span>
                }
              </div>

              <div className="form-group">
                <label for="exampleInputEmail1">
                  Existing ESIC No. (If Any)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={personalDetails.emp_esic_no}
                  onChange={onChangeInputDetails}
                  name="emp_esic_no"
                  placeholder="Enter Existing ESIC No."
                />
              </div>

              <div className="form-group">
                <label for="exampleInputEmail1">
                  PAN No. <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="pan"
                  value={personalDetails.emp_pan_card}
                  onChange={onChangeInputDetails}
                  name="emp_pan_card"
                  placeholder="Enter PAN Card No."
                />
                {/* <small style={{ color: 'red' }} id="error1"></small> */}
                {
                  empPanNumber && personalDetails.emp_pan_card !=='' &&
                  <span className='text-danger'>Please Enter a valid PAN No. like APPFD1224D  </span>
                }
              </div>

              <div className="form-group">
                <label for="exampleInputEmail1">
                  Blood Group <span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  name="emp_blood_group"
                  value={personalDetails.emp_blood_group}
                  onChange={onChangeInputDetails}
                >
                  <option hidden>Select Blood Group</option>
                  <option value="A+">(A+)</option>
                  <option value="A-">(A-)</option>
                  <option value="B+">(B+)</option>
                  <option value="B-">(B-)</option>
                  <option value="O+">(O+)</option>
                  <option value="O-">(O-)</option>
                  <option value="AB+">(AB+)</option>
                  <option value="AB-">(AB-)</option>
                </select>
              </div>

              <div className="form-group">
                <label>
                  Present Address (Street){' '}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={personalDetails.emp_present_address_street}
                  onChange={onChangeInputDetails}
                  name="emp_present_address_street"
                  placeholder="Enter Present Address"
                />
              </div>

              <div className="form-group">
                <label for="exampleInputEmail1">
                  Present City <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={personalDetails.emp_present_address_city}
                  name="emp_present_address_city"
                  onChange={onChangeInputDetails}
                  placeholder="Enter Present City"
                />
                {
                empPresentCity && personalDetails.emp_present_address_city!=='' &&
                <span className='text-danger'>City field should be allowed alphabetic characters.</span>
              }
              </div>

              <div className="form-group">
                <label for="exampleInputEmail1">
                  Present State <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={personalDetails.emp_present_address_state}
                  name="emp_present_address_state"
                  onChange={onChangeInputDetails}
                  placeholder="Enter Present State"
                />
                {
                empPresentstate && personalDetails.emp_present_address_state  &&
                <span className='text-danger'>State field should be allowed alphabetic characters.</span>
              }
              </div>

              <div className="form-group">
                <label for="exampleInputEmail1">
                  Present Postal Code <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="pin_code1"
                  value={personalDetails.emp_present_address_postal_code}
                  name="emp_present_address_postal_code"
                  onChange={onChangeInputDetails}
                  placeholder="Enter Present Postal Code"
                />
                <small style={{ color: 'red' }} id="error6"></small>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label for="exampleInputPassword1">
                  Date of Birth <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="exampleInputPassword1"
                  value={personalDetails.emp_dob}
                  max={moment()
                    .subtract(18, 'years')
                    .format('YYYY-MM-DD')}
                  onChange={onChangeInputDetails}
                  name="emp_dob"
                  disabled={isPermission('aadhaar_verify') && aadharVerified ? true : false}
                  placeholder="DD-MM-YYYY"
                />
              </div>

              <label for="exampleFormControlSelect1">
                Marital Status <span style={{ color: 'red' }}>*</span>
              </label>

              <select
                className="form-control"
                id="exampleFormControlSelect1"
                value={personalDetails.emp_marital_status}
                name="emp_marital_status"
                onChange={onChangeInputDetails}
              >
                <option hidden>Select Marital Status</option>
                <option value="SINGLE">Single</option>
                <option value="MARRIED">Married</option>
                <option value="DIVORCED">Divorced</option>
                <option value="WIDOW">Widow</option>
              </select>

              <div className="form-group" style={{ paddingTop: '15px' }}>
                <label for="email">
                  Personal Registered Email{' '}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={personalDetails.emp_personal_email}
                  onChange={onChangeInputDetails}
                  name="emp_personal_email"
                  placeholder="Enter Personal Registered Email"
                />
                {/* <small style={{ color: 'red' }} id="error5"></small> */}
                {
                  empPersonalEmail && personalDetails.emp_personal_email !=='' &&
                  <span className='text-danger'>Please enter valid email formate  </span>
                }
              </div>

              <div className="form-group">
                <label for="exampleInputPassword1">
                  Existing UAN No. (If Any)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputPassword1"
                  value={personalDetails.emp_uan_no}
                  onChange={onChangeInputDetails}
                  name="emp_uan_no"
                  placeholder="Enter Existing UAN No."
                />
              </div>

              <div className="form-group">
                <label for="exampleInputPassword1">
                  {' '}
                  Aadhaar No.{' '}
                  {isPermission('aadhaar_verify') && isAadharVerifiedSuccessfully ? (
                    <div className="badge badge-success text-lg">Verified</div>
                  ) : (
                    ''
                  )}
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="aadhar"
                  value={personalDetails.emp_aadhar_card}
                  onChange={onChangeInputDetails}
                  disabled={isPermission('aadhaar_verify') && (aadharVerified || isAadharVerifiedSuccessfully) ? true : false}
                  name="emp_aadhar_card"
                  placeholder="Enter Aadhaar No."
                />
                <small style={{ color: 'red' }} id="error2"></small>
              </div>

              <div className="form-group" style={{ paddingTop: '6px' }}>
                <label for="exampleInputPassword1">
                  Emergency Mobile No. <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="emergency"
                  value={personalDetails.emp_emergency_mobile}
                  onChange={onChangeInputDetails}
                  name="emp_emergency_mobile"
                  placeholder="Enter Emergency Mobile No."
                />
                {
                  empEmergencyNumber && personalDetails.emp_emergency_mobile !=='' &&
                  <span className='text-danger'>Please enter a valid mobile number, it must be of 10 digits and begins with 6, 7, 8 or 9.</span>
                }
                {/* <small style={{ color: 'red' }} id="error3"></small> */}
              </div>

              <div className="form-group">
                <label className="text-center">
                  Permanent Address (Street){' '}
                  <span style={{ color: 'red' }}>*</span>
                </label>
                &nbsp;
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={personalDetails.emp_permanent_address_street}
                  name="emp_permanent_address_street"
                  onChange={onChangeInputDetails}
                  disabled={aadharVerified ? true : false}
                  placeholder="Enter Permanent Address"
                />
              </div>

              <div className="form-group">
                <label for="exampleInputEmail1">
                  Permanent City <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={personalDetails.emp_permanent_address_city}
                  name="emp_permanent_address_city"
                  disabled={aadharVerified ? true : false}
                  onChange={onChangeInputDetails}
                  placeholder="Enter Permanent City"
                />
                {
                empPermanentCity && personalDetails.emp_permanent_address_city!=='' &&
                <span className='text-danger'>State field should be allowed alphabetic characters.</span>
              }
              </div>

              <div className="form-group">
                <label for="exampleInputEmail1">
                  Permanent State <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  value={personalDetails.emp_permanent_address_state}
                  name="emp_permanent_address_state"
                  onChange={onChangeInputDetails}
                  disabled={aadharVerified ? true : false}
                  placeholder="Enter Permanent State"
                />
                 {
                empPermanentstate && personalDetails.emp_permanent_address_state!=='' &&
                <span className='text-danger'>State field should be allowed alphabetic characters.</span>
              }
              </div>

              <div className="form-group">
                <label for="exampleInputEmail1">
                  Permanent Postal Code <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="pin_code"
                  value={personalDetails.emp_permanent_address_postal_code}
                  name="emp_permanent_address_postal_code"
                  onChange={onChangeInputDetails}
                  disabled={aadharVerified ? true : false}
                  placeholder="Enter Permanent Postal Code"
                />
                <small style={{ color: 'red' }} id="error7"></small>
              </div>

              <>
                {isSubmit ? (
                  <button
                    className="btn btn-primary float-right mt-3"
                    type="button"
                    onClick={savedetails}
                  >
                    Submit
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-success float-right mt-3 ml-1"
                      type="button"
                      onClick={e => onClickActive(3)}
                    >
                      Next{' '}
                      <i
                        class="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <button
                      className="btn btn-info float-right mt-3"
                      type="button"
                      onClick={updatePersonalDetails}
                    >
                      Update
                    </button>
                  </>
                )}
              </>
            </div>
          </div>
        </form>
      )}

      {filteredData &&
        filteredData.length > 0 &&
        filteredData.includes(user[0].id.toString()) && (
          <button
            className="btn btn-info float-right mt-3"
            type="button"
            onClick={() => setIsSkip(true)}
          >
            Skip
          </button>
        )}

      <Alert
        isAlert={isAlert}
        isAlertToggle={isAlertToggle}
        alertMessage={alertMessage}
        isAlertToggleClose={isAlertToggleClose}
      />
      <ToastContainer />
    </div>
  );
};
export default PersonalDetails;